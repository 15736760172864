import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import Post from '../templates/Post';
import Meta from '../components/Meta';
import Layout from '../components/Layout';
import Interstitial from '../components/Interstitial';
import Disclaimer from '../components/Disclaimer';

const WhatsAppVotePourTonAvenir = ({ data, location }) => (
  <Layout location={location}>
    <Meta
      title="Sauvons notre avenir! Votez aux élections européennes - voici comment "
      description="Cliquez sur le lien et envoyez un message à vos amis sur WhatsApp - donnez-leur une voix! ➡️"
      shareImage="francais"
      location={location}
      site={get(data, 'site.meta')}
      whatsAppMessage={`Bonjour! Tu es français / française et tu vis au Royaume-Uni? Tu as le droit de voter aux élections européennes en mai!

Envoie ce message à tes amis qui vivent au Royaume-Uni! C'est aussi chez nous!

Clique ici:
https://saveourfuture.uk/votepourtonavenir

Inscris-toi jusqu'au 7 mai
Suis ces 3 etapes
https://saveourfuture.uk/simple

(Fais le maintenant!)

 * IMPORTANT !!!*
💥Envoie ce message à tes amis au Royaume-Uni - c'est aussi chez nous !💥
Clique ici: https://saveourfuture.uk/votepourtonavenir

🗣 * CETTE FOIS, NOTRE VOIX COMPTE !!! *`}
    />
  <Interstitial>
      <p>Choisissez cinq meilleurs amis ou groupes pour envoyer votre message Whatsapp</p>
      <p>Générer le message maintenant</p>
      <p>Vous pouvez personnaliser le message à l'étape suivante…</p>
    </Interstitial>
    <Disclaimer />
  </Layout>
);

WhatsAppVotePourTonAvenir.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default WhatsAppVotePourTonAvenir;

export const query = graphql`
  query VotePourTonAvenirQuery {
    site {
      meta: siteMetadata {
        title
        description
        url: siteUrl
        author
        twitter
        adsense
      }
    }
  }
`;
